var render = function () {
  var _vm$componentInfo$com;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "DAV-workshop__space"
  }, [_c('component-title', {
    attrs: {
      "panel": "ComponentClass"
    }
  }, [_c('h3', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v("컴포넌트 추가")])]), !_vm.isLoading ? _c('Spinner', {
    attrs: {
      "propsPartial": true
    }
  }) : _c('div', {
    staticClass: "DAV-blockSelect"
  }, [!_vm.getComponentId ? _c('div', {
    staticClass: "errorLayout errorLayout__content"
  }, [_c('p', [_vm._v("데이터가 없습니다.")])]) : _c('div', [_vm.componentInfo.component.firstCategory === 'html' ? _c('div', {
    staticClass: "blockSelect__cover"
  }, [_c('dl', [_c('dt', [_vm._v("HTML 컴포넌트")]), _c('dd', [_vm._v("HTML 코드를 작성하여 개별 컴포넌트를 제작할 수 있습니다.")])])]) : _c('div', {
    staticClass: "blockSelect__thumb"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isFileLoading,
      expression: "!isFileLoading"
    }],
    staticClass: "blockSelect__spinner"
  }, [_c('i', {
    staticClass: "xi-spinner-1 xi-spin"
  })]), _c('div', {
    staticClass: "blockSelect__attach"
  }, [_c('img', {
    attrs: {
      "src": (_vm$componentInfo$com = _vm.componentInfo.component.attachFile) === null || _vm$componentInfo$com === void 0 ? void 0 : _vm$componentInfo$com.url,
      "alt": _vm.componentInfo.component.name
    },
    on: {
      "click": function click($event) {
        return _vm.setPopup({
          page: 'ComponentPreview'
        });
      },
      "error": function error($event) {
        return _vm.errorImage(_vm.componentInfo.component.firstCategory, $event);
      },
      "load": _vm.loadImage
    }
  }), _c('button', {
    staticClass: "blockSelect__thumb--preview ga_e_14",
    on: {
      "click": function click($event) {
        return _vm.setPopup({
          page: 'ComponentPreview'
        });
      }
    }
  }, [_vm._v(" 미리보기 ")])])]), _c('section', [_c('div', {
    staticClass: "DAV-titleBar"
  }, [_c('h4', [_vm._v("컴포넌트명")])]), _c('div', {
    staticClass: "frmBlock__layout"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.componentInfo.component.name,
      expression: "componentInfo.component.name"
    }],
    staticClass: "frmBlock__textField",
    class: {
      invalid: _vm.componentName.error
    },
    attrs: {
      "type": "text",
      "placeholder": _vm.componentName.message,
      "maxlength": _vm.componentName.size
    },
    domProps: {
      "value": _vm.componentInfo.component.name
    },
    on: {
      "keyup": function keyup($event) {
        return _vm.checkComponentName();
      },
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.componentInfo.component, "name", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "frmBlock__count"
  }, [_c('em', [_vm._v(_vm._s(_vm.componentInfo.component.name.length))]), _vm._v(" /" + _vm._s(_vm.componentName.size) + " ")]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.componentName.error,
      expression: "componentName.error"
    }],
    staticClass: "frmBlock__error"
  }, [_vm._v(" " + _vm._s(_vm.componentName.message) + " ")])]), _c('section', [_c('div', {
    staticClass: "DAV-titleBar"
  }, [_c('h4', [_vm._v(" 추가할 페이지 레이아웃 선택 "), _c('tooltip', {
    attrs: {
      "text": "\uCEF4\uD3EC\uB10C\uD2B8 \uCD94\uAC00 \uC2DC \uC120\uD0DD\uD55C \uB808\uC774\uC544\uC6C3 \uC601\uC5ED \uB9C8\uC9C0\uB9C9\uC5D0 \uCEF4\uD3EC\uB10C\uD2B8\uAC00 \uCD94\uAC00\uB429\uB2C8\uB2E4."
    }
  })], 1)]), _c('div', {
    staticClass: "frmBlock__display"
  }, [_c('button', {
    staticClass: "areaPosition__header",
    class: _vm.classEditableLayout('header'),
    on: {
      "click": function click($event) {
        return _vm.selectEditableLayout('header');
      }
    }
  }, [_c('span', [_vm._v("상단(Header)")])]), _c('button', {
    staticClass: "areaPosition__content",
    class: _vm.classEditableLayout('content'),
    on: {
      "click": function click($event) {
        return _vm.selectEditableLayout('content');
      }
    }
  }, [_c('span', [_vm._v("본문(Content)")])]), _c('button', {
    staticClass: "areaPosition__footer",
    class: _vm.classEditableLayout('footer'),
    on: {
      "click": function click($event) {
        return _vm.selectEditableLayout('footer');
      }
    }
  }, [_c('span', [_vm._v("하단(Footer)")])])])])])]), _c('button', {
    staticClass: "blockSelect__add",
    class: _vm.setAddClass(),
    on: {
      "click": _vm.componentSave
    }
  }, [_vm._v(" 컴포넌트 추가하기 "), _c('i', {
    staticClass: "xi-angle-right-min"
  })])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }