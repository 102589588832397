<template>
  <div class="DAV-workshop__space">
    <component-title panel="ComponentClass">
      <h3 slot="title">컴포넌트 추가</h3>
    </component-title>
    <Spinner v-if="!isLoading" :propsPartial="true"></Spinner>
    <div class="DAV-blockSelect" v-else>
      <div class="errorLayout errorLayout__content" v-if="!getComponentId">
        <p>데이터가 없습니다.</p>
      </div>
      <div v-else>
        <div
          class="blockSelect__cover"
          v-if="componentInfo.component.firstCategory === 'html'"
        >
          <dl>
            <dt>HTML 컴포넌트</dt>
            <dd>HTML 코드를 작성하여 개별 컴포넌트를 제작할 수 있습니다.</dd>
          </dl>
        </div>
        <div class="blockSelect__thumb" v-else>
          <div class="blockSelect__spinner" v-show="!isFileLoading">
            <i class="xi-spinner-1 xi-spin"></i>
          </div>
          <div class="blockSelect__attach">
            <img
              :src="componentInfo.component.attachFile?.url"
              :alt="componentInfo.component.name"
              @click="setPopup({page: 'ComponentPreview'})"
              @error="errorImage(componentInfo.component.firstCategory, $event)"
              @load="loadImage"
            />
            <button
              class="blockSelect__thumb--preview ga_e_14"
              @click="setPopup({page: 'ComponentPreview'})"
            >
              미리보기
            </button>
          </div>
        </div>
        <section>
          <div class="DAV-titleBar">
            <h4>컴포넌트명</h4>
          </div>
          <div class="frmBlock__layout">
            <input
              type="text"
              class="frmBlock__textField"
              :class="{invalid: componentName.error}"
              :placeholder="componentName.message"
              :maxlength="componentName.size"
              v-model="componentInfo.component.name"
              @keyup="checkComponentName()"
            />
          </div>
          <div class="frmBlock__count">
            <em>{{ componentInfo.component.name.length }}</em>
            /{{ componentName.size }}
          </div>
          <div class="frmBlock__error" v-show="componentName.error">
            {{ componentName.message }}
          </div>
        </section>
        <section>
          <div class="DAV-titleBar">
            <h4>
              추가할 페이지 레이아웃 선택
              <tooltip
                :text="`컴포넌트 추가 시 선택한 레이아웃 영역 마지막에 컴포넌트가 추가됩니다.`"
              />
            </h4>
          </div>
          <div class="frmBlock__display">
            <button
              class="areaPosition__header"
              :class="classEditableLayout('header')"
              @click="selectEditableLayout('header')"
            >
              <span>상단(Header)</span>
            </button>
            <button
              class="areaPosition__content"
              :class="classEditableLayout('content')"
              @click="selectEditableLayout('content')"
            >
              <span>본문(Content)</span>
            </button>
            <button
              class="areaPosition__footer"
              :class="classEditableLayout('footer')"
              @click="selectEditableLayout('footer')"
            >
              <span>하단(Footer)</span>
            </button>
          </div>
        </section>
      </div>
    </div>
    <button
      class="blockSelect__add"
      :class="setAddClass()"
      @click="componentSave"
    >
      컴포넌트 추가하기 <i class="xi-angle-right-min"></i>
    </button>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
import {setMargin} from "@/utils/editor.js";

const ModuleEditor = createNamespacedHelpers("ModuleEditor");
const ModuleEditorContainer = createNamespacedHelpers("ModuleEditorContainer");
const ModuleComponent = createNamespacedHelpers("ModuleComponent");

export default {
  data() {
    return {
      isLoading: false,
      isFileLoading: false,
      linkedComponentGroupName: "content",
      linkedComponentGroupId: null,
      componentInfo: null,
      componentName: {
        size: 15,
        error: false,
        message: "컴포넌트명을 입력해주세요."
      }
    };
  },
  watch: {
    // 컴포넌트 불러오기
    getComponentLoadData(data) {
      this.componentInfo = data;
      this.isLoading = true;
    }
  },
  computed: {
    ...ModuleEditor.mapGetters(["getComponentVersionId", "getComponentId"]),
    ...ModuleEditorContainer.mapGetters([
      "getEditablePage",
      "getEditablePageLayout",
      "getComponentLayout",
      "getComponentContainer"
    ]),
    ...ModuleComponent.mapGetters(["getComponentLoadData"])
  },
  methods: {
    // class binding - 추가하기 버튼
    setAddClass() {
      return {
        "blockSelect__add--active": !this.componentName.error
      };
    },
    // image error
    errorImage(category, e) {
      e.target.src = require(`@/assets/images/common/img_${category}.svg`);
    },
    // image load
    loadImage() {
      this.isFileLoading = true;
    },
    // 페이지 레이아웃
    classEditableLayout(layout) {
      return {
        "areaPosition--active": this.linkedComponentGroupName === layout
      };
    },
    // 페이지 레이아웃 선택
    selectEditableLayout(layout) {
      this.linkedComponentGroupId = this.getEditablePage.layouts[layout];
      this.linkedComponentGroupName = layout;
    },
    // 컴포넌트명 체크
    checkComponentName() {
      this.componentName.error = !this.componentInfo.component.name;
    },
    // 컴포넌트 저장
    componentSave() {
      if (this.componentName.error) {
        return;
      }

      // getComponentContainer 가장 큰 index 키 찾아서 추가
      const keys = [...this.getComponentContainer.keys()];
      const maxValue = keys.length ? Math.max(...keys) : 0;

      let addItem = this.componentInfo;
      addItem.id = null;
      addItem.index = maxValue + 1;
      addItem.userLinkedComponentGroupId = this.linkedComponentGroupId;
      addItem.componentVersionId = this.getComponentVersionId;
      addItem.name = this.componentInfo.component.name;
      addItem.etc = setMargin(addItem.etc);
      this.getComponentContainer.set(maxValue + 1, addItem);

      let layout = this.getEditablePageLayout(this.linkedComponentGroupId);
      let layoutLength = this.getComponentLayout[layout].length;
      this.getComponentLayout[layout].splice(layoutLength, 0, maxValue + 1);

      this.setPanel("ComponentList");
      this.setEvent("add");
      this.setAttach(true);
    },
    // 컴포넌트 load
    componentLoad() {
      let payload = {};
      payload.componentId = this.getComponentId;

      this.actComponentLoad(payload).then(() => {});
    },

    ...ModuleEditor.mapMutations(["setPopup", "setPanel", "setAttach"]),
    ...ModuleEditorContainer.mapMutations(["setEvent"]),
    ...ModuleComponent.mapActions(["actComponentLoad"])
  },
  mounted() {
    // 컴포넌트 불러오기
    this.componentLoad();

    // 연동 컴포넌트 그룹 init
    this.linkedComponentGroupId =
      this.getEditablePage.layouts[this.linkedComponentGroupName];
  },
  components: {
    Spinner: () => import("@/components/basis/spinner.vue"),
    ComponentTitle: () => import("@/components/editor/ComponentTitle.vue")
  }
};
</script>

<style lang="scss" scoped>
.DAV-blockSelect {
  z-index: 1;
  position: absolute;
  top: 56px;
  right: 0;
  bottom: 0;
  left: 0;
  margin-bottom: 64px;
  overflow-y: auto;

  section {
    border-bottom: 8px solid #f7f9fc;
    background-color: #ffffff;
    padding: 0 16px 24px;
  }
}
.blockSelect__cover {
  position: relative;
  height: 254px;
  overflow: hidden;
  background-color: #f7f9fc;
  display: flex;
  align-items: center;
  justify-content: center;

  dl {
    padding-top: 127px;
    background-image: url($path + "bg_html_component.svg");
    background-repeat: no-repeat;
    background-position: 50% 0;
    text-align: center;
  }
  dt {
    display: inline-block;
    padding-left: 38px;
    background-image: url($path + "bul_html_component.svg");
    background-repeat: no-repeat;
    background-position: 0 50%;
    color: #309574;
    font-size: 18px;
    font-weight: 500;
  }
  dd {
    margin-top: 5px;
    color: #484948;
    font-size: 14px;
  }
}
.blockSelect__thumb {
  position: relative;
  height: 254px;
  overflow: hidden;
  background-color: #c4c4c4;

  img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    cursor: pointer;
  }
  &--preview {
    position: absolute;
    right: 16px;
    bottom: 16px;
    width: 24px;
    height: 24px;
    background-image: url($path + "btn_preview.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 24px auto;
    text-indent: -9999px;
  }
}
.blockSelect__spinner {
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c4c4c4;

  i {
    color: #309574;
    font-size: 30px;
  }
}
.blockSelect__add {
  z-index: 2;
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 64px;
  background-color: #dcdcdc;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;

  &--active {
    background-color: #54c7a2;
  }
}
/* 반응형 - desktop */
@media screen and (min-width: 1024px) {
  .blockSelect__add {
    width: 421px;
  }
}
/* 반응형 - mobile */
@media screen and (max-width: 1023px) {
}
</style>
